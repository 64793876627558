@keyframes shake {
  0%, 100% {
    transform: rotate(0);
  }
  25%, 50% {
    transform: rotate(-2deg);
  }
  75% {
    transform: rotate(2deg);
  }
}

.animate-shake {
  animation: shake 0.25s ease-in-out infinite;
}


